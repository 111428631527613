@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@200;400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Blinker', sans-serif;
  position: relative;
}

html {
  font-size: 62.5%;
}
.story{
background-color:white;
padding: 30px 30px 45px;
}

@media(max-width: 750px){
    .story{
        background-color: white;
        padding: 30px 5px 45px;
        }
}

.middle-tile {
  padding-left: 50px!important;
  padding-bottom: 25px!important;
  padding-top: 50px!important;
  padding-right: 50px!important;
  background-color: white;
  transform: translateX(0px) translateY(-80px);
  max-width: 1240px;
  width: 80%;
  margin-left: 10%;
  position: relative;
  font-size: 100%;
}

.middle-tile-plus {
  transform: translateX(0px) translateY(0px);
  max-width: 1240px;
  width: 50%;
  position: relative;
}

.middle-tile-plus-mobile {
  transform: translateX(0px) translateY(0px);
  max-width: 1240px;
  position: relative;
}

.bottom-container { 
  display: flex; /* Use flexbox layout */ 
  justify-content: space-between; /* Optional: space between components */ 
  
} 
 
.component-a {
  overflow: hidden;
  padding: 20px 0px 20px; 
  flex: 1; /* Allow components to grow equally */ 
}
.component-b { 
  padding: 20px 0px 20px; 
  flex: 1; /* Allow components to grow equally */ 
} 


/* global */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 19px;
}

.aari-container {
    columns: 2 auto;
    padding: 0 19px;
  }

  @media (max-width:450px) {
    .aari-container {
        max-width: 800px;
        padding: 0 19px;
        columns: auto;
      }
  }

.poster {
    background-Repeat:no-repeat;
    background-Size: cover;
    height:35vw;
    width:100vw;
    display: flex;
    background-position: center;
}
.poster-mobile {
  background-Repeat:no-repeat;
  background-Size: cover;
  height:80vw;
  width:100vw;
  display: flex;
  background-position: center;
}
@media (max-width: 750px){
    .poster {
        height:35vw;
      }
    }

.grid-container {
  display: grid;
  height: 50rem;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'one two four' 'one two-low four-low';
  gap: 1.3rem;
  margin-top: 1.3rem;
}
@media (max-width: 750px){
    .grid-container {
        display: inline;
        height: 50rem;
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 'one two four' 'one two-low four-low';
        gap: 1.3rem;
        margin-top: 1.3rem;
      }
    }

.aari-grid-container {
    display: block;
    height: 50rem;      
    gap: 1.3rem;
   
}

/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 50%;
    padding: 40px;
  }

.same-height-images {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
}
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

.home-container {
  padding-top: 12rem;
}

.featured {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.main-description {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: white;
  font-size: 3.8rem;
  font-weight: 600;
}

.title-text {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: white;
  font-size: 3.8rem;
  font-weight: 600;
}

.title-row {
  width: 100%;
  margin: 0 auto;
  margin-left: 270px;
}
.title-row-mobile {
  width: 100%;
  margin: 0 auto;
  margin-left: 160px;
}
.title-block {
  width: 120px;
  display: inline-block;
  margin-left: 3px;
  color: aliceblue;
  font-size: inherit;
}

.clear-fix :after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.container-title {

  padding-top: 1.25em;
  font-weight: 600;
  line-height: 1em;
  }

  et-info-phone:before {
    content: "\e090";
    position: relative;
    top: 2px;
    margin-right: 2px;
    }
    
.featured img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.grid-one {
  grid-area: one;
}

.grid-two {
  grid-area: two;
}

.grid-four {
  grid-area: four;
}

.grid-four-low {
  grid-area: four-low;
}

.lil-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in;
}

.aari-lil-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in;
  }



#img1:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#img2:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#img3:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#img4:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* @media (max-width:750px) {
  .grid-container {
      height: 500px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas:
          "one two"
          "four four-low";
      grid-gap: 13px;
      gap: 13px;
  }
} */

@media (max-width:450px) {
  .main-description {
      bottom: 1rem;
      left: 1rem;
      font-size: 3rem;
  }
}

@media (max-width:400px) {
  .main-description {
      bottom: 1rem;
      left: 0.5rem;
      font-size: 2.5rem;
  }
}